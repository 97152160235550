export default class SlickVehicleGallery {
    public static init() {
		if ($('.slick--vehicle-gallery-main').length > 0) {
			$('.slick--vehicle-gallery-main').slick({
				slidesToScroll: 1,
				arrows: true,
				prevArrow: "<span class='slick-prev main'>&lt;</span>",
				nextArrow: "<span class='slick-next main'>&gt;</span>",
				autoplay: false,
				centerMode: true,
				variableWidth: true,
				focusOnSelect: true,
				responsive: [
					{
						breakpoint: 755,
						settings: {
							centerMode: false,
							variableWidth: false
						}
					}
				],
			});
			$('.slick--vehicle-gallery-main').each(function () {
				(<any>$(this)).magnificPopup({
					delegate: '.slick-slide:not(.slick-cloned) a',
					type: 'image',
					gallery: {
					  enabled: true,
					  preload: [0,1] // Will preload 0 - before current, and 1 after the current image
					},
					callbacks: {
						elementParse: function(item:any) {
						  console.log(item.el[0].className);
						  if(item.el[0].className == 'video') {
							item.type = 'iframe'
						  } else {
							 item.type = 'image'
						  }
					
						}
					  }
				});
			});
		}
	}
}